import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {SeguridadService} from '@/services/seguridad.service';

export const EsAdminGuard: CanActivateFn = (
    route : ActivatedRouteSnapshot,
    state : RouterStateSnapshot
) => {

    const seguridadService = inject(SeguridadService);
    const router = inject(Router);

    var cli = seguridadService.obtenerCliente();
    console.log(seguridadService.obtenerRol());
    if (cli.toUpperCase() != 'SHOPOLOGY' && (state.url.toUpperCase().indexOf('EVIDENCIA') >= 0 || state.url.toUpperCase().indexOf('INMEDIATA') >= 0)) {
        console.log('salir');
        router.navigate(['/home']);
        return false;
    }

    
    if (seguridadService.obtenerRol() === 'Admin') {
        return true;
    }

    router.navigate(['/home']);
    return false;

}
