import { Routes } from '@angular/router';

import { ValidarLogueoGuard } from '@guards/validar-logueo.guard';
import { ValidarTokenGuard } from '@guards/validar-token.guard';
import { EsConsultorGuard } from '@guards/es-consultor.guard';
import { EsAdminGuard } from '@guards/es-admin.guard';
import { EsSoporteGuard } from '@guards/es-soporte.guard';
import { EsHabitGuard } from './guards/es-habit.guard';

export const routes: Routes = [
    {
        path:'auth',
        canActivate: [ValidarLogueoGuard],
        children: [
            {
                path: 'login',
                title: 'Inicio de Sesion',
                loadComponent: () => import('./pages/auth/login.component')
            },
            {
                path:'', redirectTo: 'login', pathMatch: 'full',
            }
        ]        
    },
    {
        path:'home',
        canActivate: [ValidarTokenGuard],
        title: 'Home',
        loadComponent: () => import('./pages/main/main.component'), 
        children: [
            {
                path:'', 
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/dashboard/dashboard.component'), 
                data: {titulo: 'Dashboard'}
            },
            {
                path:'dashboard', 
                title: 'Dashboard',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/dashboard/dashboard.component'), 
                data: {titulo: 'Dashboard'}
            },
            {
                path:'usuarios', 
                title: 'Usuarios',
                canActivate: [EsAdminGuard],
                loadComponent: () => import('./pages/usuarios/usuarios.component'), 
                data: {titulo: 'Usuarios'}, 
            },
            {
                path:'elementos', 
                title: 'Elementos',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/elementos/elementos.component'), 
                data: {titulo: 'Elementos'}
            },
            {
                path:'segmentos', 
                title: 'Segmentos',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/segmentos/segmentos.component'),  
                data: {titulo: 'Segmentos'} 
            },
            {
                path:'empresas', 
                title: 'Empresas',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/empresas/empresas.component'),  
                data: {titulo: 'Empresas'}
            },
            {
                path:'perfil', 
                title: 'Perfil',
                loadComponent: () => import('./pages/usuarios/perfil/perfil.component'),  
                data: {titulo: 'Perfil'}
            },
            {
                path:'roles', 
                title: 'Roles',
                canActivate: [EsAdminGuard],
                loadComponent: () => import('./pages/roles/roles.component'),  
                data: {titulo: 'Roles'} 
            },
            {
                path:'areasHabit', 
                title: 'Areas Habit',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/areas-habit/areas-habit.component'),
                data: {titulo: 'Areas Habit'}
            },
            {
                path:'puestosHabit', 
                title: 'Puestos Habit',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/puestos-habit/puestos-habit.component'),
                data: {titulo: 'Puestos Habit'}
            },
            {
                path:'contactos', 
                title: 'Contactos',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/contactos/contactos.component'), 
                data: {titulo: 'Contactos'}
            },
            {
                path:'supervisores', 
                title: 'Supervisores',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/supervisores/supervisores.component'), 
                data: {titulo: 'Supervisores'} 
            },
            {
                path:'regiones', 
                title: 'Regiones',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/regiones/regiones.component'), 
                data: {titulo: 'Regiones'}
            },
            {
                path:'concesionarios', 
                title: 'Concesionarios',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/concesionarios/concesionarios.component'), 
                data: {titulo: 'Concesionarios'} 
            },
            {
                path:'categorias',
                title: 'Categorias', 
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/categorias/categorias.component'),
                data: {titulo: 'Categorias'}
            },
            {
                path:'lideresTerritorio',
                title: 'Lideres de Territorio',
                canActivate: [EsConsultorGuard], 
                loadComponent: () => import('./pages/lideres-territorio/lideres-territorio.component'), 
                data: {titulo: 'Lideres de Territorio'}
            },
            {
                path:'sucursales', 
                title: 'Sucursales',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/sucursales/sucursales.component'), 
                data: {titulo: 'Sucursales'}
            },
            {
                path:'asesores', 
                title: 'Asesores',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/asesores/asesores.component'), 
                data: {titulo: 'Asesores'}
            },
            {
                path:'estandares', 
                title: 'Estándares',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/estandares/estandares.component'),
                data: {titulo: 'Estándares'}
            },
            {
                path:'estandaresCalidad', 
                title: 'Estándares de Calidad',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/estandares-calidad/estandares-calidad.component'),
                data: {titulo: 'Estándares de Calidad'}
            },
            {
                path:'politicas', 
                title: 'Políticas',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/politicas/politicas.component'), 
                data: {titulo: 'Políticas'}
            },
            {
                path:'politicas/:datos/preguntas', 
                title: 'Preguntas',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/preguntas/preguntas.component'),  
                data: {titulo: 'Políticas / Preguntas'}
            },
            {
                path:'politicas/:datos/preguntas/:datos2/consejos', 
                title: 'Consejos',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/consejos/consejos.component'),  
                data: {titulo: 'Políticas / Preguntas / Consejos'}
            },
            {
                path:'politicas/:datos/instrucciones',
                title: 'Instrucciones',
                canActivate: [EsConsultorGuard], 
                loadComponent: () => import('./pages/instrucciones/instrucciones.component'), 
                data: {titulo: 'Política / Instrucciones'}
            },
            {
                path:'politicas/:datos/instrucciones/:datos2/observaciones',
                title: 'Observaciones', 
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/observaciones/observaciones.component'), 
                data: {titulo: 'Políticas / Instrucciones / Observaciones'}
            },
            {
                path:'politicasBonus', 
                title: 'Políticas Bonus',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/politicas-bonus/politicas-bonus.component'), 
                data: {titulo: 'Políticas Bonus'}
            },
            {
                path:'politicasBonus/:datos/preguntasBonus', 
                title: 'Preguntas Bonus',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/preguntas-bonus/preguntas-bonus.component'), 
                data: {titulo: 'Política Bonus / Preguntas Bonus'}
            },
            {
                path:'recomendaciones', 
                title: 'Recomendaciones',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/recomendaciones/recomendaciones.component'),
                data: {titulo: 'Recomendaciones'}
            },
            {
                path:'visitas', 
                title: 'Visitas',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/visitas/visitas.component'),
                data: {titulo: 'Visitas'}
            },
            {
                path:'actividades', 
                title: 'Actividades',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/actividades/actividades.component'), 
                data: {titulo: 'Actividades'}
            },
            {
                path:'tareasProgramadas', 
                title: 'Tareas Programadas',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/tareas-programadas/tareas-programadas.component'),  
                data: {titulo: 'Tareas Programadas'}
            },
            {
                path:'decisiones', 
                title: 'Decisiones',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/decision/decision.component'),
                data: {titulo: 'Decisiones'}
            },
            {
                path:'decisiones/:datos/acciones', 
                title: 'Acciones',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/accion-decision/accion-decision.component'), 
                data: {titulo: 'Decisiones / Acciones'}
            },
            {
                path:'decisionesTarima', 
                title: 'Decisiones Tarima',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/decision-tarima/decision-tarima.component'),
                data: {titulo: 'Decisiones Tarima'}
            },
            {
                path:'problemasGenerales',
                title: 'Problemas Generales',
                canActivate: [EsConsultorGuard], 
                loadComponent: () => import('./pages/problema-general/problema-general.component'), 
                data: {titulo: 'Problemas Generales'}
            },
            {
                path:'problemasGenerales/:datos/problemasEspecificos', 
                title: 'Problemas Especificos',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/problemas-especificos/problemas-especificos.component'),
                data: {titulo: 'Problemas Generales / Problemas Especificos'}
            },
            {
                path:'notas', 
                title: 'Notas',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/notas/notas.component'),
                data: {titulo: 'Notas'}
            },
            {
                path:'preguntasEvidencia', 
                title: 'Preguntas de Evidencia',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/preguntas-evidencia/preguntas-evidencia.component'),
                data: {titulo: 'Preguntas de Evidencia'}
            },
            {
                path:'evidencias', 
                title: 'Evidencias',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/evidencias/evidencias.component'),
                data: {titulo: 'Evidencias'}
            },
            {
                path:'accionesInmediatas', 
                title: 'Acciones Inmediatas',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/acciones-inmediatas/acciones-inmediatas.component'),
                data: {titulo: 'Acciones Inmediatas'}
            },
            {
                path:'restablecerSucursal', 
                title: 'Restablecer Sucursal',
                canActivate: [EsSoporteGuard],
                loadComponent: () => import('./pages/sucursales/restablecer/restablecer.component'),
                data: {titulo: 'Restablecer Sucursal'}
            },
            {
                path:'directorio', 
                title: 'Directorio',
                canActivate: [EsSoporteGuard],
                loadComponent: () => import('./pages/directorio/directorio.component'), 
                data: {titulo: 'Directorio'}
            },
            {
                path:'llamadas', 
                title: 'Llamadas',
                canActivate: [EsSoporteGuard],
                loadComponent: () => import('./pages/llamadas/llamadas.component'),
                data: {titulo: 'Llamadas'}
            },
            {
                path:'tickets', 
                title: 'Tickets',
                canActivate: [EsSoporteGuard],
                loadComponent: () => import('./pages/tickets/tickets.component'), 
                data: {titulo: 'Tickets'}
            },
            {
                path:'ejecutarAsignacion', 
                title: 'Asignacion Automatica',
                canActivate: [EsSoporteGuard],
                loadComponent: () => import('./pages/asignacion-automatica/asignacion-automatica.component'), 
                data: {titulo: 'Asignacion Automatica'}
            },
            {
                path:'buscadorCorreos', 
                title: 'Buscador de Correos',
                canActivate: [EsSoporteGuard],
                loadComponent: () => import('./pages/buscador-correo/buscador-correo.component'),
                data: {titulo: 'Buscador de Correos'}
            },
            {
                path:'reportes', 
                title: 'Reportes',
                canActivate: [ValidarTokenGuard],
                loadComponent: () => import('./pages/reportes/reportes.component'),
                data: {titulo: 'Reportes'}
            },
            {
                path:'confirmacionEnvio',
                title: 'Confirmación de Envió',
                canActivate: [EsSoporteGuard], 
                loadComponent: () => import('./pages/confirmacion-envio/confirmacion-envio.component'),
                data: {titulo: 'Confirmación de Envió'}
            },
            {
                path:'calidadEventos', 
                title: 'Calidad de Eventos',
                canActivate: [EsSoporteGuard],
                loadComponent: () => import('./pages/calidad-evento/calidad-evento.component'),
                data: {titulo: 'Calidad de Eventos'}
            },
            {
                path:'asignacionEvidencia', 
                title: 'Asignacion de Evidencias',
                canActivate: [EsSoporteGuard],
                loadComponent: () => import('./pages/asignacion-evidencia/asignacion-evidencia.component'),
                data: {titulo: 'Asignacion de Evidencias'}
            },
            {
                path:'bitacora', 
                title: 'Bitacora',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/bitacora/bitacora.component'),
                data: {titulo: 'Bitacora'}
            },
            // {
            //     path:'lideres', 
            //     canActivate: [EsHabitGuard],
            //     loadComponent: () => import('./pages/lideres/lideres.component'),
            //     data: {titulo: 'Líderes'}
            // },
            {
                path:'conversaciones', 
                title: 'Conversaciones',
                canActivate: [EsHabitGuard],
                loadComponent: () => import('./pages/conversaciones/conversaciones.component'),
                data: {titulo: 'Conversaciones'}
            },
            {
                path:'bibliotecaVideos', 
                title: 'Biblioteca de Videos',
                canActivate: [EsHabitGuard],
                loadComponent: () => import('./pages/biblioteca-videos/biblioteca-videos.component'),
                data: {titulo: 'Biblioteca de Videos'}
            },
            {
                path:'entrevistas', 
                title: 'Entrevistas',
                canActivate: [EsHabitGuard],
                loadComponent: () => import('./pages/entrevista/entrevista.component'),
                data: {titulo: 'Entrevistas'}
            },
            {
                path:'tareasHabit', 
                title: 'Tareas Habit',
                canActivate: [EsHabitGuard],
                loadComponent: () => import('./pages/tareas-habit/tareas-habit.component'),
                data: {titulo: 'Tareas Habit'}
            },
            {
                path:'reportesHabit', 
                title: 'Reportes Habit',
                canActivate: [EsHabitGuard],
                loadComponent: () => import('./pages/reportes-habit/reportes-habit.component'),
                data: {titulo: 'Reportes Habit'}
            },
            // {
            //     path:'empresasHabit', 
            //     canActivate: [EsHabitGuard],
            //     loadComponent: () => import('./pages/empresa-habit/empresa-habit.component'),
            //     data: {titulo: 'Empresas Habit'}
            // },            
            {
                path:'motivosHabit', 
                title: 'Motivos Habit',
                canActivate: [EsHabitGuard],
                loadComponent: () => import('./pages/motivo-habit/motivo-habit.component'),
                data: {titulo: 'Motivos Habit'}
            },
            {
                path:'configuracionSistema', 
                title: 'Configuracion del Sistema',
                canActivate: [EsConsultorGuard],
                loadComponent: () => import('./pages/configuracion/configuracion.component'), 
                data: {titulo: 'Configuracion del Sistema'}
            },
            {
                path:'**', 
                title: 'Error',
                loadComponent: () => import('./pages/no-page-found/no-page-found.component'), 
                data: {titulo: 'Página de error 404'}
            }
        ]        
    },
    {
    path:'cam',
    title: 'Cam',
    loadComponent: () => import('./checkup/checkup.component')
    },
    {
    path:'**', redirectTo: 'auth'
    }
];

